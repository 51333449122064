<template>
    <section class="obras-vigentes-ver-control container-fluid">
        <titulo-divisor titulo="Carpetas" class="mx-0">
            <div class="row">
                <div class="col-auto  pl-1">
                    <button class="btn btn-general f-12 px-3" @click="executeAll">Ejecutar todos</button>
                </div>
            </div>
        </titulo-divisor>
        <section class="listado-calendarios overflow-auto custom-scroll">
            <div class="row justify-content-center mx-0">
                <div class="col-12 lista-chequeos-collapse">
                    <el-collapse v-model="activeNames" @change="handleChange">
                        <el-collapse-item name="1">
                            <template slot="title">
                                <div class="d-flex f-600 w-100 justify-content-between">
                                    <p class="text-general">Lista de chequeo 1</p>
                                    <div class="d-middle-center br-4 my-auto ml-3 f-300 icon-option">
                                        <el-tooltip :content="zoomCalendar ? 'Acercar':'Alejar'" effect="light" placement="bottom" visible-arrow>
                                            <i :class="`${zoomCalendar ? 'icon-magnify-plus-outline':'icon-magnify-minus-outline'} f-20`" @click="zoomInOut" />
                                        </el-tooltip>
                                        <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                            <template #content>
                                                <p class="p-2 cr-pointer item" @click="createActivitiesGroup">Crear grupo de actividades</p>
                                                <p class="p-2 cr-pointer item" @click="loadCalendar">Cargar calendario</p>
                                            </template>
                                            <i class="icon-plus-circle f-25 cr-pointer" />
                                        </el-tooltip>
                                        <i class="icon-calendar-end f-20" @click="executeCalendar" />
                                    </div>
                                </div>
                            </template>
                            <calendar :zoomCalendar="zoomCalendar" />
                        </el-collapse-item>
                        <el-collapse-item name="2">
                            <template slot="title">
                                <div class="d-flex f-600 w-100 justify-content-between">
                                    <p class="text-general">Lista de chequeo 1</p>
                                    <div class="d-middle-center br-4 my-auto ml-3 f-300 icon-option">
                                        <i class="icon-magnify-minus-outline f-20" />
                                        <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                            <template #content>
                                                <p class="p-2 cr-pointer item" @click="createActivitiesGroup">Crear grupo de actividades</p>
                                                <p class="p-2 cr-pointer item" @click="loadCalendar">Cargar calendario</p>
                                            </template>
                                            <i class="icon-plus-circle f-25 cr-pointer" />
                                        </el-tooltip>
                                        <i class="icon-calendar-end f-20" @click="executeCalendar" />
                                    </div>
                                </div>
                            </template>
                            <div class="py-3">
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </section>
        
        <!-- partials -->
        <modal-crear-grupo-actividades ref="crearGrupoActividades" />
        <modal-cargar-calendario ref="modalCargarCalendario" />
        <modal ref="abrirModalEjecutarTodos" titulo="Iniciar ejecución" adicional="Iniciar" @adicional="startAllCalendars">
            <div class="row m-3 f-12">
                <p class="col-12 text-center px-0">¿Está seguro que quiere iniciar la ejecución de todos los cronogramas?</p>
            </div>
        </modal>
        <modal ref="abrirModalEjecutarCalendario" titulo="Iniciar ejecución" adicional="Iniciar" @adicional="startCalendar">
            <div class="row m-3 f-12">
                <p class="col-12 text-center">¿Está seguro que quiere iniciar la ejecución del cronograma?</p>
            </div>
        </modal>
    </section>
</template>

<script>

export default {
    components: {
        modalCrearGrupoActividades: () => import('./partials/modalCrearGrupoActividades.vue'),
        modalCargarCalendario: () => import('./partials/modalCargarCalendario.vue'),
        calendar: () => import('./components/calendar.vue'),
        
    },
    data(){
        return{
            activeNames: ['1'],
            zoomCalendar: false
        }
    },
    mounted(){
        
    },
    methods:{
        executeAll(){
            this.$refs.abrirModalEjecutarTodos.toggle()
        },
        startAllCalendars(){
            this.$refs.abrirModalEjecutarTodos.toggle()
        },
        createActivitiesGroup(){
            this.$refs.crearGrupoActividades.toggle()
        },
        loadCalendar(){
            this.$refs.modalCargarCalendario.toggle()
        },
        executeCalendar(){
            this.$refs.abrirModalEjecutarCalendario.toggle()
        },
        startCalendar(){
            this.$refs.abrirModalEjecutarCalendario.toggle()
        },
        handleChange(){

        },
        zoomInOut(){
            this.zoomCalendar = !this.zoomCalendar
        }
    }
}
</script>

<style lang="scss" scoped>
.obras-vigentes-ver-control{
    .listado-calendarios{
        height: calc(100vh - 460px);
    }
}
</style>